import { mapHelper } from "@/utils/utils.js";

const EXAMINE_WAIT = 0; // 待审核
// const EXAMINE_ING = ''; // 审核中
const EXAMINE_PASS = 1; // 审核通过
const EXAMINE_REFUSE = 2; // 审核未通过

// 审核状态
const examineStatus = [
  { text: '待审核', value: EXAMINE_WAIT },
  { text: '审核通过', value: EXAMINE_PASS },
  { text: '审核未通过', value: EXAMINE_REFUSE }
];
const { map: examineStatusMap, setOps: setExamineStatusOps } = mapHelper.setMap(examineStatus);

export { EXAMINE_WAIT, EXAMINE_PASS, EXAMINE_REFUSE, examineStatus, examineStatusMap, setExamineStatusOps };
