var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trusteeship-child"},[_c('div',{staticClass:"scroll-content"},[_c('img',{staticClass:"banner-img",attrs:{"src":require("./img/sqty_banner.png"),"alt":"社区托幼"}}),_c('div',{staticClass:"yuyue-content"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了","error":_vm.error,"error-text":"请求失败，点击重新加载","immediate-check":false},on:{"update:error":function($event){_vm.error=$event},"load":_vm.getList},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('ul',{staticClass:"yuyue-list"},_vm._l((_vm.yuyueList),function(item,index){return _c('li',{key:'wdyy' + index,staticClass:"yuyue-item",on:{"click":function($event){return _vm.yuyuItemOnClick(item, index)}}},[_c('div',{staticClass:"header-text"},[_c('div',{staticClass:"value-text"},[_vm._v(" 姓名："+_vm._s(item.childrenName || "-")+" ")]),_c('div',{staticClass:"value-text age-text"},[_vm._v(" 年龄："+_vm._s(item.childrenAge || "-")+"岁 ")])]),_c('div',{staticClass:"content-text"},[_c('div',{staticClass:"value-text"},[_c('span',{staticClass:"label-text"},[_vm._v("托幼周期：")]),_vm._v(" "+_vm._s(item.kindergartenTime)+" ")]),_c('div',{staticClass:"value-text"},[_c('span',{staticClass:"label-text"},[_vm._v("状态：")]),_c('span',{class:[
                    'tag',
                    {
                      dsh: item.auditStatus === _vm.EXAMINE_WAIT,
                      shtg: item.auditStatus === _vm.EXAMINE_PASS,
                      shwtg: item.auditStatus === _vm.EXAMINE_REFUSE,
                    },
                  ]},[_vm._v(" "+_vm._s(_vm.examineStatusMap[item.auditStatus] || "-")+" ")])])])])}),0)])],1)]),_c('div',{staticClass:"footer-handle"},[_c('div',{staticClass:"wyyy-btn",on:{"click":_vm.appointOnClick}},[_vm._v("我要预约")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }